<template>
    <div :class="[`lg-product-wrapper product-list-card`, {'colum-2 two-colum-parent-product col-lg-6': showTwoColumn}]">
        <div class="inner">
            <div class="lg-left-content planesContainer">
                <div class="thumbnail">
                    <img :src="productDate.productImage" :alt="productDate.productName" @load="$emit('handleImageLoad')">
                </div>
                <div class="read-content">
                    <div class="product-share-wrapper">

                    </div>
                    <a href="#Tab">
                        <h6 class="title">{{ productDate.productName }}</h6>
                    </a>
                    <div id="columna-container" style="display:flex; flex-direction: row;">
                        <div id="columna1" style="display:flex; flex-direction: column;">
                        <div>
                            <span>
                                <img :src="productDate.dImage1" class="imgTilde">
                                {{ productDate.d1 }}
                            </span>
                        </div>
                        <div>
                            <span>
                                <img :src="productDate.dImage2" class="imgTilde">
                                {{ productDate.d2 }}
                            </span>
                        </div>
                        <div>
                            <span>
                                <img :src="productDate.dImage3" class="imgTilde">
                                {{ productDate.d3 }}
                            </span>
                        </div>
                        <div>
                            <span>
                                <img :src="productDate.dImage4" class="imgTilde">
                                {{ productDate.d4 }}
                            </span>
                        </div>
                        <div>
                            <span>
                                <img :src="productDate.dImage5" class="imgTilde">
                                {{ productDate.d5 }}
                            </span>
                        </div>
                        <div>
                            <span>
                                <img :src="productDate.dImage6" class="imgTilde">
                                {{ productDate.d6 }}
                            </span>
                        </div>

                        <div>
                            <span>
                                <img :src="productDate.dImage7" class="imgTilde">
                                {{ productDate.d7 }}
                            </span>
                        </div>
                        <div>
                            <span>
                                <img :src="productDate.dImage8" class="imgTilde">
                                {{ productDate.d8 }}
                            </span>
                        </div>
                        </div>
                        <div id="columna2" style="display:flex ; flex-direction: column; margin-left: 10vh;">
                            <div><h6>{{productDate.cMotor}}</h6></div>
                            <div><span><img :src="productDate.vImage" class="imgTilde">{{productDate.m1}}</span></div>
                            <div><span><img :src="productDate.vImage" class="imgTilde">{{productDate.m2}}</span></div>
                            <div><span><img :src="productDate.vImage" class="imgTilde">{{productDate.m3}}</span></div>
                            <div><p>-</p></div>
                            <div><h6>{{productDate.cVersion}}</h6></div>
                            <div><span><img :src="productDate.vImage" class="imgTilde">{{productDate.v1}}</span></div>
                        </div>
                    </div>

                </div>
                <button type="button"
                        class="btn btn-primary-alta mr--30 mediaBoton2">
                        <a :href="productDate.url" class="btn">Contratar Plan</a>
                </button>
            </div>
            <button type="button"
                        class="btn btn-primary-alta mr--30 mediaBoton1">
                        <a :href="productDate.url" class="btn">Contratar por {{productDate.price.currency}}{{productDate.price.amount}} /mes </a>
                </button>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'ProductListCard',
        props: {
            productDate: {},
            productStyleClass: {
                type: String
            },
            showPlaceBid: {
                type: Boolean,
                default: false
            },
            showTwoColumn: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped>
.planesContainer{
    display: flex;
    flex-wrap: wrap;
}
.mediaBoton2{ display: none;}
@media screen and (max-width: 768px){
    .imgTilde{
        width: 15px;
        height: 15px;
    }
    .mediaBoton1{
        display: none;
    }
    .mediaBoton2{
        display: block;
    }
}

@media screen and (max-width: 533px){
    .imgTilde{
        width: 15px;
        height: 15px;
    }
}
@media screen and (max-width: 410px){
    .mediaBoton2{
        width: 25vh;
    }
}

</style>
