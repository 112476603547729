<template>
    <Layout>
        <header class="flex-centered">
            <section id="image-section" class="flex-centered">
                <img id="back" src="/Servidores/back.webp" alt="">
                <img src="/Servidores/steve.png" alt="">
            </section>
            <section id="info-section" class="flex-centered">
                <img src="/Servidores/pickaxe.png" alt="" id="pickaxe">
                <h1 id="title">SERVIDORES PRE-CONFIGURADOS </h1>
                <p id="description">Listos para usar en cuestión de minutos, nuestros servidores pre-configurados te
                    permiten disfrutar de Minecraft sin las tediosas tareas de configuración e instalación.</p>
            </section>
        </header>
        <section id="planes">
            <h1>LOS ELEGIDOS DE TMT GAMING</h1>
            <div id="planes-cards">
                <div class="card" v-for="(plan, index) in servidores.planes" :key="index" @click="goToPlan(plan)">
                    <img :src="plan.image" alt="">
                    <div class="info flex-centered">
                        <div class="description">
                            <b>{{ plan.title }}</b>
                            <p>{{ plan.description }}</p>
                        </div>
                        <span class="buy">${{ plan.price }}</span>
                    </div>
                </div>
            </div>
        </section>

        <section id="footer" class="flex-centered">
            <img src="/Servidores/horsesteve.png" alt="">
            <p>¡Elige el servidor que mejor se adapte a tu estilo de juego y empieza a divertirte hoy mismo!</p>
            <p>¡No esperes más, únete a nuestra comunidad y comienza tu aventura en Minecraft!</p>
        </section>
    </Layout>
</template>

<script setup>
import Layout from '@/components/layouts/Layout'
import servidores from '../../../servidores.json';
</script>

<script>
export default {
    methods: {
        goToPlan(plan) {
            this.$router.push(plan.link)
        }
    }
}
</script>

<style scoped lang="scss">
header {
    height: 720px;

    #image-section,
    #info-section {
        height: 100%;
    }

    #image-section {
        position: relative;
        width: 50%;
        justify-content: start;

        overflow: hidden;

        img {
            transform: scale(.8);
        }

        #back {
            transform: scale(1.1);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;

            filter: brightness(.6) blur(6px);
        }
    }

    #info-section {
        width: 50%;
        background: var(--negro);

        padding: 2em;
        flex-direction: column;
        text-align: center;
    }
}

#planes {
    width: 90%;
    margin: auto;
    margin-top: 4em;

    h1 {
        font-size: 30px;
        letter-spacing: 3px;
        text-align: center;
    }

    #planes-cards {
        display: flex;
      justify-content: center;
        gap: 1em;
        flex-wrap: wrap;

        .card {
            width: 370px;
            height: 310px;
            margin-bottom: 2em;

            background: transparent !important;

            img {
                widows: 100%;
                height: 220px;
                border-radius: 20px;
                margin-bottom: 1em;
            }

            .info {
                justify-content: space-between;

                .description {
                    width: 70%;
                    margin-bottom: 1em;

                    b {
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        letter-spacing: 1.44px;
                    }

                    p {
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.24px;
                    }
                }

                .buy {
                    text-align: center;
                    width: fit-content;
                    padding: .5em 1em;
                    border-radius: 20px;
                    background: linear-gradient(#79E3F0, #1D7F8B);
                    color: white;
                }
            }
        }
    }
}

#footer {
    flex-direction: column;

    width: 90%;
    margin: auto;
    margin-top: 4em;

    img {
        width: 160px;
        height: 225px;
    }

    p {
        margin: 0;
    }
}

@media screen and (max-width: 1360px) {
    header {
        height: 600px;
    }

}

@media screen and (max-width: 500px) {
    header {
        flex-direction: column;

        #image-section {
            display: none;
        }

        #info-section {
            width: 100%;
        }
    }

}
</style>