<template>
    <layout>
        <!-- start Banner Area -->
        <div class="rn-slider-area">
            <carousel class="slider-activation-banner-5 game-banner-slick-wrapper slick-arrow-style-one rn-slick-dot-style"
                      :items-to-show="1"
                      :wrap-around="true"
                      id="carouselTop">
                <slide v-for="(carouselItem, index) in carouselItems"
                       :key="index" id="sliderArea">
                       <video :src="carouselItem.video" id="video" autoplay muted loop></video>
                    <div :class="`d-flex align-items-center padding-controler-slide-product justify-content-center slide slide-style-2 fullscreen_image-banner position-relative`">
                        <div class="container">
                            <div class="row d-flex align-items-center">
                                <div class="col-lg-7">
                                    <div class="inner text-start">
                                        <a href="#">
                                        <h1 class="h">{{ carouselItem.title }}</h1>
                                        <h4 class="h">{{carouselItem.description}}</h4>
                                        </a>
                                        <div class="product-share-wrapper lg-product-share">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5">
                                    <div class="place-bet-area into-banner">
                                        <div>
                                            <img :src="carouselItem.banner">
                                        </div>

                                    </div>
                                    <div class="property-wrapper-flex d-flex text-start">

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="tooltip-bottom-wrapper d-flex">
                                    <div class="share-react react d-flex">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </slide>
                <template #addons>
                    <Pagination/>
                </template>
            </carousel>
        </div>
        <!-- End Banner Area -->

        <!-- About Market Place Start -->
        <div class="about-market-area rn-section-gapTop">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="about-wrapper text-center">
                            <h2>¿Te gustaría un Hosting sin vecinos molestos?</h2>
                            <p class="discription">
                                Contratando en TMTHosting te garantizamos <br>
                                un monitoreo y balanceo constante del uso de recursos del servidor
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- About Market Place End -->

        <!-- Explore Style Carousel -->

        <div id="exploreStyle">
            <explore-live-carousel/>
        </div>
        <!-- Explore Style Carousel End-->

        <!-- Start Tab Product area -->
        <div class="rn-product-area rn-section-gapTop" id="Tab">
            <div class="container">
                <explore-list-style :is-one-column="true"/>
            </div>
        </div>
        <!-- end Tab Product area -->

        <!-- start service area -->
        <div id="serviceStyle">
           <service-style-one/> 
        </div>
        
        <!-- End service area -->

        <share-modal/>

        <report-modal/>

        <placebid-modal/>
    </layout>
</template>

<script>
    import Layout from '@/components/layouts/Layout'
    import SellerStyleTwo from '@/components/seller/SellerStyleTwo'
    import ExploreLiveCarousel from '@/components/explore/ExploreLiveCarousel'
    import ServiceStyleOne from '@/components/service/ServiceStyleOne'
    import CollectionStyleOne from '@/components/collection/CollectionStyleOne'
    import ExploreListStyle from '@/components/explore/ExploreListStyle'
    import NotificationListSidebar from '@/components/notification/NotificationListSidebar'
    import CreatorListSidebar from '@/components/seller/CreatorListSidebar'
    import ShareModal from '@/components/modal/ShareModal'
    import ReportModal from '@/components/modal/ReportModal'
    import PlacebidModal from '@/components/modal/PlacebidModal'
    import SalScrollAnimationMixin from '@/mixins/SalScrollAnimationMixin'
    import Countdown from '@/components/product/Countdown'
    import {Carousel, Slide, Pagination} from 'vue3-carousel'

    export default {
        name: 'HomePageNine',
        components: {
            Countdown,
            PlacebidModal,
            ReportModal,
            ShareModal,
            CreatorListSidebar,
            NotificationListSidebar,
            ExploreListStyle,
            CollectionStyleOne,
            ServiceStyleOne,
            ExploreLiveCarousel,
            SellerStyleTwo,
            Layout,
            Pagination,
            Carousel,
            Slide
        },
        mixins: [SalScrollAnimationMixin],
        data() {
            return {
                carouselItems: [
                    {
                        id: 1,
                        image: 'bg_image--21',
                        title: 'TMT Gaming',
                        description: 'Te oferce el mejor Hosting Minecraft en Argentina ',
                        banner: require(`@/assets/images/banner/banner-3.jpg`),
                        video: require(`@/assets/images/banner/minecraft03.mp4`),
                    },// segunda pestaña

                    {
                        id: 2,
                        image: 'bg_image--22',
                        title: 'TMT Gaming',
                        description: `Todos nuestros planes son en Pesos Argentinos`,
                        banner: require(`@/assets/images/banner/banner-2.jpg`),
                        video: require(`@/assets/images/banner/minecraft01.mp4`),
                    },//tercera pestaña
                    {
                        id: 3,
                        image: 'bg_image--23',
                        title: 'TMT Gaming',
                        description: 'Servidores con tecnologia Oracle',
                        banner: require(`@/assets/images/banner/banner-1.jpg`),
                        video: require(`@/assets/images/banner/minecraft02.mp4`),
                    },//primera pestaña
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .header-right-fixed {
        top: 90px;
    }
#video{
    position: absolute;
    z-index: -2;
    width: 100%;
    transform: translateY(100vh);
    transform: translateX(0px);

}
#sliderArea{
    height: 100vh;
}
</style>
