<template>
    <layout>
        <video src="@/assets/images/banner/zoids.mp4" autoplay loop muted id="videoContacto"></video>
        <breadcrumb title="Contacto" current="Contacto"/>
        <div class="rn-contact-top-area rn-section-gapTop bg_color--5">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-12" data-sal="slide-up" data-sal-delay="150" data-sal-duration="800">
                        <div class="section-title mb--30 text-center">
                            <h2 class="title">Contáctenos</h2>
                            <p class="description">
                                Estamos listos y esperando sus preguntas
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row g-5">
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12" data-sal="slide-up" data-sal-delay="150" data-sal-duration="800">
                        <div class="rn-address">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                     stroke-linejoin="round" class="feather feather-headphones">
                                    <path d="M3 18v-6a9 9 0 0 1 18 0v6"></path>
                                    <path
                                        d="M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z">
                                    </path>
                                </svg>
                            </div>
                            <div class="inner">
                                <h4 class="title">Teléfonos</h4>
                                <p><a href="tel:+5491122729393">11-2272-9393</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12" data-sal="slide-up" data-sal-delay="200" data-sal-duration="800">
                        <div class="rn-address">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                     stroke-linejoin="round" class="feather feather-mail">
                                    <path
                                        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                    </path>
                                    <polyline points="22,6 12,13 2,6"></polyline>
                                </svg>
                            </div>
                            <div class="inner">
                                <h4 class="title">Emails</h4>
                                <p><a href="mailto:soporte@tmtgaming.com.ar">soporte@tmtgaming.com.ar</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12" data-sal="slide-up" data-sal-delay="250" data-sal-duration="800">
                        <div class="rn-address">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                     stroke-linejoin="round" class="feather feather-map-pin">
                                    <!-- <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path> -->
                                    <path
                                        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                    </path>
                                    <circle cx="12" cy="10" r="3"></circle>
                                </svg>
                            </div>
                            <div class="inner">
                                <h4 class="title">Sumate a nuestro equipo</h4>
                                <p><a href="mailto:trabajos@tmthosting.com.ar">trabajos@tmthosting.com.ar</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="login-area message-area rn-section-gapTop">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-6" data-sal="slide-up" data-sal-delay="150" data-sal-duration="800">
                        <div class="connect-thumbnail">
                            <div class="left-image">
                                <img :src="require(`@/assets/images/contact/contact1.png`)" alt="Nft_Profile">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6" data-sal="slide-up" data-sal-delay="200" data-sal-duration="800">
                        <div class="form-wrapper-one registration-area">
                            <h3 class="mb--30">Contacto</h3>
                            <contact-form/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="rn-contact-map-area position-relative rn-section-gapTop">
            <div class="container">
                <div class="row g-5">
                    <div class="col-12" data-sal="slide-up" data-sal-delay="150" data-sal-duration="800">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d3298.736323555333!2d-58.3852054!3d-34.6636818!3m2!1i1024!2i768!4f13.1!5e1!3m2!1ses-419!2sar!4v1658172284116!5m2!1ses-419!2sar" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
    import Layout from "@/components/layouts/Layout";
    import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
    import SalScrollAnimationMixin from "@/mixins/SalScrollAnimationMixin";
    import ContactForm from "@/components/contact/ContactForm";

    export default {
        name: 'Contact',
        components: {ContactForm, Breadcrumb, Layout},
        mixins: [SalScrollAnimationMixin]
    }
</script>

<style scoped>
#videoContacto{
    position: fixed;
    z-index: -1;
    width: 100%;
}
*{
    margin: 0px;
}
</style>
