<template>
    <div class="rn-live-bidding-area rn-section-gapTop">
        <div class="container">
            <carousel class="portfolio-slider" :items-to-show="1"  :wrap-around="true" :breakpoints="breakpoints">
                <template #addons>
                    <div class="carousel-header">
                        <div class="section-title">
                            <h3 class="title mb--0 live-bidding-title" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                {{ title }}
                            </h3>
                        </div>
                    </div>
                </template>
                <slide v-for="(carouselItem, index) in liveBiddingItems" :key="index" id="slide" @click="clickSlide">
                    <product-card
                        :product-date="carouselItem"
                    />
                </slide>
            </carousel>
        </div>
    </div>
</template>

<script>
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Navigation } from 'vue3-carousel'
    import ProductCard from '@/components/product/ProductCard'

    export default {
        name: 'ExploreLiveCarousel',
        components: {
            ProductCard,
            Carousel,
            Slide,
            Navigation,
        },
        props: {
            title: {
                type: String,
                default: 'Modalidades disponibles:'
            },
            showPlaceBid: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                // NO TOCAR LOS BREAKPOINTS
                breakpoints: {
                    576: {
                        itemsToShow: 2,
                        snapAlign: 'left'
                    },
                    768: {
                        itemsToShow: 2,
                        snapAlign: 'left'
                    },
                    992: {
                        itemsToShow: 3,
                        snapAlign: 'left'
                    },
                    1200: {
                        itemsToShow: 4,
                        snapAlign: 'left'
                    },
                    1399: {
                        itemsToShow: 5,
                        snapAlign: 'left'
                    }
                },
                liveBiddingItems: [
                    {
                        id: 1,
                        productId: 1,
                        productImage: require(`@/assets/images/portfolio/portfolio-07.png`),
                        productName: 'NetCraft',
                        productDescription: 'Es momento de hacer más amigos, y NetCraft te da la oportunidad de lograrlo.'
                    },
                    {
                        id: 2,
                        productId: 2,
                        productImage: require(`@/assets/images/portfolio/portfolio-03.png`),
                        productName: 'TecniCraft',
                        productDescription: 'Entendemos del juego y sabemos que esta modalidad necesita muchos recursos. Por eso, este servidor es para vos.'
                    },
                    {
                        id: 0,
                        productId: 0,
                        productImage: require(`@/assets/images/portfolio/portfolio-10.png`),
                        productName: 'FriendsCraft',
                        productDescription: 'Juega con hasta 25 amigos.'
                    }
                ],
            }
        },
        methods: {
            clickSlide(){
                let slideId = document.getElementById('slide');
                console.log();
            }
        },
    }
</script>

<style scoped>
#slide{
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(284.5px);
    margin-right: 5%;
    margin-left: 5%;
}

@media only screen and (max-width: 500px) {
  #slide {
    transform: translate(0, 0) !important;
    margin: 0;
  }
}

</style>
