<template>
    <div>
        <!-- Start Footer Area -->
        <div class="rn-footer-one rn-section-gap bg-color--1 mt--100 mt_md--80 mt_sm--80">
            <div class="container" id="footer">
                <div class="row gx-5">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="widget-content-wrapper">
                            <div class="footer-left">
                                <logo/>
                                <p class="rn-footer-describe">
                                   Hosting Minecraft<br><b>Argentino y en Pesos</b>
                                </p>
                            </div>

                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-6 col-12 mt_mobile--40">
                        <div class="footer-widget widget-quicklink">
                            <h6 class="widget-title">TMTGaming</h6>
                            <ul class="footer-list-one">
                                <li class="single-list">
                                    <router-link to="#">Inicio</router-link>
                                </li>
                                <!--li class="single-list">
                                    <router-link to="#">About</router-link>
                                </li-->
                                <li class="single-list">
                                    <a href="/#exploreStyle">Modalidades</a>
                                </li>
                                <li class="single-list">
                                    <a href="/#Tab" >Planes</a>
                                </li>
                                <li class="single-list">
                                    <a href="/#serviceStyle">Servicios</a>
                                </li>
                                <!--li class="single-list">
                                    <router-link to="#">Contacto</router-link>
                                </li-->
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                        <div class="footer-widget widget-information">
                            <h6 class="widget-title">Información</h6>
                            <ul class="footer-list-one">
                                <li class="single-list">
                                    <router-link to="#">Telefono: 11-2272-9393</router-link>
                                </li>
                                <li class="single-list">
                                    <router-link to="#">E-Mail: soporte@tmtgaming.com.ar</router-link>
                                </li>
                                <li class="single-list">
                                    <router-link to="#">Sumate: trabajos@tmthosting.com.ar</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <!-- End Footer Area -->

        <!-- Start Copyright Area -->
        <div class="copy-right-one ptb--20 bg-color--1">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="copyright-left">
                            <span>©2022 TMTGaming, Inc. All rights reserved.</span>
                            <!--ul class="privacy">
                                <li>
                                    <router-link to="/terms-condition">Terms</router-link>
                                </li>
                                <li>
                                    <router-link to="/privacy-policy">Privacy Policy</router-link>
                                </li>
                            </ul-->
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="copyright-right">
                            <social-links/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Copyright Area -->

        <div class="mouse-cursor cursor-outer"/>
        <div class="mouse-cursor cursor-inner"/>

        <!-- Start Top To Bottom Area  -->
        <back-to-top @click="scrollTop"/>
        <!-- End Top To Bottom Area  -->
    </div>
</template>

<script>
    import BackToTop from '@/components/layouts/footer/BackToTop'
    import Logo from "@/components/logo/Logo";
    import SocialLinks from "@/components/social/SocialLinks";

    export default {
        name: 'Footer',
        components: {SocialLinks, Logo, BackToTop},
        methods: {
            scrollTop() {
                window.scrollTo(0, 0);
            }
        }
    }
</script>
