import { createRouter, createWebHistory } from "vue-router";
import HomePageOne from "../views/home/HomePageOne";
import Contact from "../views/Contact";
import Pavos from "../views/Pavos/Pavos.vue";
import Servidores from "../views/Servidores/Servidores.vue";
import Detalle from "../views/Servidores/Detalle.vue";
import Blog from "../views/Blog/Blog.vue";
import DetalleBlog from "../views/Blog/Detalle.vue";

const routes = [
  {
    path: "/",
    name: "HomePageOne",
    component: HomePageOne,
    meta: {
      title: "TMTGaming",
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
    meta: {
      title: "Contacto",
    },
  },
  {
    path: "/pavos",
    name: "Pavos",
    component: Pavos,
    meta: {
      title: "Pavos",
    },
  },
  {
    path: "/servidores",
    name: "Servidores",
    component: Servidores,
    meta: {
      title: "Servidores",
    },
  },
  {
    path: "/servidores/:id",
    name: "DetallePlan",
    component: Detalle,
    meta: {
      title: "Detalle de plan",
    },
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
    meta: {
      title: "Blog",
    },
  },
  {
    path: "/blog/:id",
    name: "BlogDetail",
    component: DetalleBlog,
    meta: {
      title: "Detalle de blog",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
  window.scrollTo(0, 0);
});

export default router;
