export default {
    data() {
        return {
            products: [
                {
                    id: 10,
                    productImage: require(`@/assets/images/portfolio/friendscraft-hierro.png`),
                    productName: 'FriendsCraft - Hierro',
                    d1: '4GB de RAM',
                    d2: '10 Slots',
                    d3: '5GB SSD',
                    d4: 'Soporte por mail',
                    d5: '',
                    d6: '',
                    d7: '',
                    d8: '',
                    url: 'https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c9380848cee0ea5018d1413e7e41c1d',
                    dImage1: require(`@/assets/images/tilde.png`),
                    dImage2: require(`@/assets/images/tilde.png`),
                    dImage3: require(`@/assets/images/tilde.png`),
                    dImage4: require(`@/assets/images/tilde.png`),
                    dImage5: '',
                    dImage6: '',
                    dImage7: '',
                    dImage8: '',
                    price: {
                        amount: 12000,
                        currency: '$'
                    },
                    cMotor:'Motor Minecraft',
                    m1:' Paper (spigot mejorado)',
                    m2:' BungeCord',
                    m3:' Vanila',
                    cVersion:'Versión Minecraft',
                    v1:' 1.08 - 1.20.5',
                    vImage: require(`@/assets/images/tilde-azul.png`),
                },
                {
                    id: 11,
                    productImage: require(`@/assets/images/portfolio/friendscraft-oro.png`),
                    productName: 'FriendsCraft - Oro',
                    d1: '8GB de RAM',
                    d2: 'Hasta 50 Slots',
                    d3: '15GB SSD',
                    d4: 'Soporte por Tickets',
                    d5: '',
                    d6: '',
                    d7: '',
                    d8: '',
                    url: 'https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c9380848cee0ec1018d1419201b1bd1', //cambiar por link de Oro
                    dImage1: require(`@/assets/images/tilde.png`),
                    dImage2: require(`@/assets/images/tilde.png`),
                    dImage3: require(`@/assets/images/tilde.png`),
                    dImage4: require(`@/assets/images/tilde.png`),
                    dImage5: '',
                    dImage6: '',
                    dImage7: '',
                    dImage8: '',
                    price: {
                        amount: 20500,
                        currency: '$'
                    },
                    cMotor:'Motor Minecraft',
                    m1:' Paper (spigot mejorado)',
                    m2:' BungeCord',
                    m3:' Vanila',
                    cVersion:'Versión Minecraft',
                    v1:' 1.08 - 1.19',
                    vImage: require(`@/assets/images/tilde-azul.png`),
                },
                {
                    id: 12,
                    productImage: require(`@/assets/images/portfolio/friendscraft-diamante.png`),
                    productName: 'FriendsCraft - Diamante',
                    d1: '12GB de RAM',
                    d2: 'Slots Ilimitados',
                    d3: '4 vCPU Exclusivas',
                    d4: 'Minecraft Forge',
                    d5: '40GB SSD',
                    d6: 'Soporte Exclusivo',
                    d7: '',
                    d8: '',
                    url: 'https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c9380848d10141f018d1419ecb7034a', //cambiar por link de Diamante
                    dImage1: require(`@/assets/images/tilde.png`),
                    dImage2: require(`@/assets/images/tilde.png`),
                    dImage3: require(`@/assets/images/tilde.png`),
                    dImage4: require(`@/assets/images/tilde.png`),
                    dImage5: require(`@/assets/images/tilde.png`),
                    dImage6: require(`@/assets/images/tilde.png`),
                    dImage7: '',
                    dImage8: '',
                    price: {
                        amount: 30000,
                        currency: '$'
                    },
                    cMotor:'Motor Minecraft',
                    m1:' Paper (spigot mejorado)',
                    m2:' BungeCord',
                    m3:' Vanila',
                    cVersion:'Versión Minecraft',
                    v1:' 1.08 - 1.20',
                    vImage: require(`@/assets/images/tilde-azul.png`),
                }
            ],
            products1: [
                {
                    id: 20,
                    productImage: require(`@/assets/images/portfolio/netcraft-esmeralda.png`),
                    biddingAmount: '10',
                    productName: 'NetCraft - Esmeralda',
                    d1: '1 Servidor Bungecord',
                    d2: '1GB de RAM',
                    d3: '1 Base de Datos MySQL',
                    d4: '5GB Disco SSD por server',
                    d5: '2 Servidores para otras modalidades',
                    d6: '4GB cada Servidor',
                    d7: '15GB Disco SSD por server',
                    d8: '2 Base de Datos MySQL',
                    url: 'https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c9380848cee0ec1018d141bd9311bda',
                    dImage1: require('@/assets/images/tilde.png'),
                    dImage2: require('@/assets/images/tilde.png'),
                    dImage3: require('@/assets/images/tilde.png'),
                    dImage4: require('@/assets/images/tilde.png'),
                    dImage5: require('@/assets/images/tilde.png'),
                    dImage6: require('@/assets/images/tilde.png'),
                    dImage7: require('@/assets/images/tilde.png'),
                    dImage8: '',
                    price: {
                        amount: 35000,
                        currency: '$'
                    },
                    cMotor:'Motor Minecraft',
                    m1:' Paper (spigot mejorado)',
                    m2:' BungeCord',
                    m3:' Vanila',
                    cVersion:'Versión Minecraft',
                    v1:' 1.08 - 1.20',
                    vImage: require(`@/assets/images/tilde-azul.png`),
                }
                
            ],
            products2:[
                {
                    id: 30,
                    productImage: require(`@/assets/images/portfolio/tecnicraft-redstone.png`),
                    productName: 'TecniCraft',
                    d1: '16GB de RAM',
                    d2: 'Configuración de backups automáticos',
                    d3: 'Slots ilimitados',
                    d4: '1 Base de Datos MySQL',
                    d5: '1 cuenta de FTP',
                    d6: 'Disco SSD',
                    d7: 'Java Edition - Plugins',
                    d8: 'Protección DDoS',
                    url: 'https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c9380848d10141f018d141d57d00354',
                    dImage1: require(`@/assets/images/tilde.png`),
                    dImage2: require(`@/assets/images/tilde.png`),
                    dImage3: require(`@/assets/images/tilde.png`),
                    dImage4: require(`@/assets/images/tilde.png`),
                    dImage5: require(`@/assets/images/tilde.png`),
                    dImage6: require(`@/assets/images/tilde.png`),
                    dImage7: require(`@/assets/images/tilde.png`),
                    dImage8: require(`@/assets/images/tilde.png`),
                    price: {
                        amount: 38500,
                        currency: '$'
                    },
                    cMotor:'Motor Minecraft',
                    m1:' Paper (spigot mejorado)',
                    m2:' 4vCPU exclusivos',
                    m3:' ',
                    cVersion:'Versión Minecraft',
                    v1:' 1.08 - 1.20',
                    vImage: require(`@/assets/images/tilde-azul.png`),
                }
            ]
        }
    }
}
