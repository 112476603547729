<template>
    <div>
        <Header />
        <mobile-menu />
        <main>
            <slot></slot>
        </main>
        <Footer />
        <back-to-top />
    </div>
</template>

<script>
import Header from './header/Header'
import Footer from './footer/Footer'
import MobileMenu from './header/MobileMenu'
import BackToTop from '@/components/layouts/footer/BackToTop'

export default {
    name: 'Layout',
    components: { BackToTop, MobileMenu, Footer, Header }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --negro: #14121D;
    --azul-oscuro: #1E1E26;
}

.flex-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

img {
    pointer-events: none;
    user-select: none;
}
</style>