<template>
    <div class="logo-custom-css" id="logo">
        <a class="logo-light" href="#">
            <img :src="require(`@/assets/images/logo/logotmtg-white.png`)" alt="nft-logo" class="logotmt">
        </a>
        <a class="logo-dark" href="#">
            <img :src="require(`@/assets/images/logo/logotmtg-dark.png`)" alt="nft-logo" class="logotmt">
        </a>
    </div>
</template>

<script>
    export default {
        name: 'Logo'
    }
</script>

<style scoped>
#logo{
    width: 13vh;
}

</style>