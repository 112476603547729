<template>
    <div :class="`product-style-one ${productStyleClass}`">
        <div class="card-thumbnail">
            <a href="#Tab" @click="clickMenu">
                <img :src="productDate.productImage" :alt="productDate.productName" @load="$emit('handleImageLoad')">
            </a>
        </div>
        <div class="product-share-wrapper">

        </div>
        <a href="#Tab" @click="clickMenu">
            <div :id='productDate.productId'>
                <span class="product-name" id="nombreProducto">{{ productDate.productName }}</span>
            </div>
            <div class="description">
                <p>{{productDate.productDescription}}</p>
            </div>
        </a>

        <div class="bid-react-area">

        </div>
    </div>
</template>

<script>

    export default {
        name: 'ProductCard',
        props: {
            productDate: {},
            productStyleClass: {
                type: String
            },
            showPlaceBid: {
                type: Boolean,
                default: false
            },
            productDescription:{
                type: String
            }
        },
        watch: {
            '$route': function (to, from) {
                this.closeModal()
            }
        },
        methods: {
            closeModal() {
                const modals = document.querySelectorAll('.modal-backdrop');

                if (modals.length) {
                    modals.forEach(item => {
                        item.remove();
                    })
                }
            },
            clickMenu (){
                if(this.productDate.productId === 0){
                    let botonFriendsCraftId = document.getElementById('nav-friendscraft-tab');
                    botonFriendsCraftId.click();
                    }
                if(this.productDate.productId === 1){
                    let botonNetCraftId = document.getElementById('nav-netcraft-tab');
                    botonNetCraftId.click();
                    }
                if(this.productDate.productId === 2){
                    let botonTecniCraftId = document.getElementById('nav-tecnicraft-tab');
                    botonTecniCraftId.click();
                    }
            }
        }
    }
</script>
