<template>
  <Layout>
    <div v-if="blog">
      <header class="flex-centered">
        <img :src="blog.image" alt="">
        <div id="overlay"></div>
        <div id="description" class="flex-centered">
          <h1>{{ blog.title }}</h1>

        </div>
      </header>
      <section id="blog-content">
        <p v-html="detail"></p>
      </section>
    </div>
  </Layout>
</template>

<script setup>
import blogs from "../../../blogs.js";
import Layout from '@/components/layouts/Layout'
</script>

<script>
export default {
  mounted() {
    let name = this.$route.params.id;
    let blog = blogs.find((x) => {
      if (x.name === name) return x;
    });

    this.blog = blog;
    this.detail = blog.content;
    this.detail = blog.content;
  },
  data() {
    return {
      blog: null,
      detail: null,
    }
  }
}
</script>

<style scoped lang="scss">
header {
  height: 500px;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    filter: brightness(.6) blur(2px);
  }

  #overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  }

  #description {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    flex-direction: column;

    max-height: 90%;
    overflow-y: auto;

    pointer-events: none;
    user-select: none;

    h1 {
      text-align: center;
    }

    .subtitle {
      margin: 0;
      width: 60%;
      text-align: center;
    }

    .attributes {
      flex-direction: column;
      margin-top: 1em;

      i {
        margin-top: 1em;
        transform: scale(2);
        margin-bottom: 1em;
      }
    }
  }
}

p,
h1,
h2 {
  text-shadow: 1px 1px black;
  color: white;
}

#buy-section {
  width: 90%;
  margin: auto;
  margin-top: 15em;

  background: var(--azul-oscuro);
  height: 550px;

  border-radius: 50px;

  flex-direction: column;

  position: relative;

  img {
    width: 306px;
    height: 315px;

    position: absolute;
    top: 0;
    left: 0;

    transform: translate(-10%, -50%);
  }

  .buy-section {
    width: 60%;
    text-align: center;

    margin-bottom: 4em;

    h1 {
      color: #FFF;
      text-align: center;
      font-family: Inter;
      font-size: 26px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: 5px;
    }

    p {
      color: #979797;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .buy-actions {
    margin-top: 2em;

    a {
      padding: 1em 2em;
      border-radius: 30px;
      margin: 0 1em;
      cursor: pointer;
      user-select: none;
    }

    #price {
      border: 1px solid #72DCE9;
    }

    #buy {
      color: white;
      background: linear-gradient(180deg, #71DBE8 0%, #2A8E9A 100%);
    }
  }
}

@media screen and (max-width: 500px) {
  #head {
    display: none;
  }

  #buy-section {
    height: fit-content;
    padding: 5em 0;

    .buy-section {
      width: 85% !important;
    }
  }
}

@media screen and (max-width: 1300px) {
  #head {
    width: 225px !important;
    height: 240px !important;
  }
}
</style>

<style lang="scss">
.attributes {
  pointer-events: none;
  user-select: none;

  p,
  b,
  h2 {
    text-align: center;
  }

  p,
  b {
    color: white;
    text-shadow: 1px 1px black;
  }

  h2 {
    text-align: center;
    margin-bottom: 0;
    text-shadow: 1px 1px black;

  }
}

#blog-content {
  width: 80%;
  margin: auto;
  margin-top: 6em;
}
</style>