export default [
  {
    "name": "test-blog",
    "title": "Guia de como instalar un mod en el launcher de Minecraft.",
    "description": "¿Queres aprender a instalar el mod que viste y te encantó? Veni que te explicamos como.",
    "link": "/blog/test-blog",
    "image": "https://www.minecraft.net/content/dam/games/minecraft/key-art/MC-Vanilla_Media-Block-Image_PC-Bundle-Keyart_800x450.jpg",
    "content": `
      <h1 style="margin: 1;">¿Como instalar un mod en minecraft?</h1>
      <b style="margin: 1;">Guia completa para instalar mods en un launcher de Minecraft:<br>¿Quieres personalizar tu experiencia en Minecraft e ir más allá de la experiencia de siempre? ¡Los mods son la solución! Con ellos, podes agregar nuevas funcionalidades, corregir errores, e incluso transformar por completo el juego.</b><br>
      <p style="margin-top: 1.5rem;">En este articulo aprenderas a como instalar un mod</p>
      <p>A continuación, los pasos para instalar correctamente un mod:<br>
      <b>1. Descarga e instala Forge:</b><br>
      Visita la página web oficial de Forge (www.curseforge.com) y selecciona la versión compatible con tu Minecraft.<br>
      Descarga el archivo de instalación y ejecuta el instalador siguiendo las instrucciones en pantalla.</p>
      <img src="https://www.minecrafteo.com/wp-content/uploads/2021/11/forge.jpg"><br>

      <p style="margin-top: 3rem;"><b>2. Instala el mod:</b><br>
      Busca el mod que deseas instalar en sitios web como CurseForge o Modrinth.<br> Asegúrate de que sea compatible con tu versión de Minecraft y Forge.
      Descarga el archivo del mod y muévelo a la carpeta "mods" dentro de tu carpeta de Minecraft.</p><br>
      <img src ="https://www.wikihow.com/images_en/thumb/a/a3/Install-Minecraft-Mods-Step-9-Version-3.jpg/v4-460px-Install-Minecraft-Mods-Step-9-Version-3.jpg.webp"><br>

      <p style="margin-top: 3rem;"><b>3. Activa el mod:</b><br>
      Inicia el launcher de Minecraft y selecciona la versión de Forge que instalaste.<br>
      Haz clic en "Jugar" y luego en Opciones, ve a la sección "Mods".
      Busca el mod que instalaste en la lista<br> y haz clic en "Activar".
      Si es necesario, reinicia Minecraft.</p>
      <img src="https://pbs.twimg.com/media/GGem1t_WsAAglRM?format=jpg&name=large"><br>
      <p style="padding-top: 1rem;">En TMTGaming ofrecemos servidores pre-configurados con MODS listos para ser disfrutados, contamos con el mejor soporte del mercado y tenemos un excelente trato con nuestros clientes <br> 
      Si estas buscando un producto el cual sea rápido, sencillo y eficaz no dudes en hablarnos; Nosotros estamos para vos. A continuación te dejo un enlace para que puedas ver los productos que ofrecemos! <a href="https://tmtgaming.ar/#Tab">Planes!</a></p>
      <img src="https://www.tmthosting.ar/userfiles/media/default/logotmtg-white2163e857.png" style="width:300px; margin: auto;"><br>
      <p style="margin-top: 2rem;"><b>Consejos adicionales:</b><br>
      Crea una copia de seguridad de tu mundo antes de instalar mods.
      Lee la descripción del mod antes de instalarlo para comprender su funcionamiento y compatibilidad.<br>
      Utiliza un modpack si quieres instalar varios mods a la vez.
      Investiga y elige mods compatibles entre sí para evitar errores.<br>
      Presta atención a los requisitos de cada mod, como la versión de Minecraft y Forge necesaria.
      Actualiza Forge y los mods regularmente para mantener una experiencia óptima.<br>
      ¡Disfruta de una experiencia de Minecraft única y personalizada con mods!</p>
      <img src="https://t30911801.p.clickup-attachments.com/t30911801/dc4a85fc-3d12-4b3a-a88e-d204c90d5947/mc%20mods.jpg">
    `


  },
  {
    "name": "premium-vs-nopremium",
    "title": "Premium vs No Premium",
    "description": "¿Sabias las diferencias que hay entre los usuarios premium y los piratas? Acá te explicamos.",
    "link": "/blog/premium-vs-nopremium",
    "image": "https://pbs.twimg.com/media/GGew49zWUAAzH20?format=jpg&name=medium",
    "content": `
        <h1 style="margin: 1;">Premium vs No Premium: Entendiendo las diferencias en Minecraft</h1>
        <p>Minecraft, el popular juego de construcción y aventuras de Mojang Studios, ofrece a los jugadores dos opciones distintas para acceder al juego: la cuenta premium y la cuenta no premium.<br>A primera vista, estas dos opciones pueden parecer similares, pero hay diferencias significativas que vale la pena explorar. En este artículo, examinaremos las diferencias entre una cuenta premium y una cuenta no premium en Minecraft.</p>
        <img src="https://cdn.hobbyconsolas.com/sites/navi.axelspringer.es/public/media/image/2017/02/guia-todos-trucos-consejos-minecraft.jpg?tf=3840x" style="margin-bottom: 2rem; display: block; width: 900px; height: auto;margin: auto;"><br>
        <h5>Cuenta Premium: Acceso total y Benficios exclusivos:</h5>
        <p style="margin-bottom: 0;">Una cuenta premium de Minecraft es aquella que ha sido adquirida legalmente a través del sitio web oficial de Minecraft o de un distribuidor autorizado. Al comprar una cuenta premium, los jugadores obtienen acceso completo al juego y a una serie de beneficios exclusivos:</p><br>
        <h5>Actualizaciones Constantes:</h5><p>Los propietarios de cuentas premium reciben todas las nuevas actualizaciones y parches del juego de manera automatica sin necesidad de descargar nada, asi asegurando que siempre tengan acceso a las versiones mas nuevas del juego apenas salen.</p><br>
        <h5 style="margin-top: 3rem;">Acceso a Servidores Oficiales:</h5><p>Los jugadores con cuentas premium tienen el privilegio de poder acceder a los servidores de Minecraft, donde pueden participar en gran variedad de modos de juegos, eventos y comunidades organizadas por Mojang. O tambien, de servidores reconocidos como Hypixel, CubeCraft, entre otros.</p><br>

        <h5 style="margin-top: 3rem;">Seguridad y Soporte:</h5><p>Las cuentas premium vienen con medidas de seguridad adicionales, como la verificacion en dos pasos, que sirve para proteger la cuenta ante el robo o el acceso no autorizado por el propietario. Ademas, los titulares de cuentas premium reciben soporte tecnico prioritario en caso de tener algun problema o consulta relacionada al juego</p><br>

        <h5>Personalización Avanzada:</h5><br><p> Los jugadores con cuentas premium tienen acceso a una amplia gama de opciones de personalización, incluyendo la capacidad de cambiar sus nombres de usuario, seleccionar skins personalizados y participar en el programa de Minecraft Marketplace para comprar y vender contenido creado por la comunidad.</p><br>

        <h4>Cuenta No Premium: Acceso Limitado y Restricciones</h4>
        <img src ="https://pbs.twimg.com/media/GGe8SkrXMAAB7Ye?format=jpg&name=large"><br>
        <p style="margin-top: 1.5rem;">Por otro lado, una cuenta no premium de Minecraft es aquella que no ha sido adquirida legalmente a través de los canales oficiales de Mojang Studios. Aunque los jugadores con cuentas no premium pueden acceder al juego de forma gratuita, hay varias limitaciones y restricciones que deben tener en cuenta:</p><br>

        <h5>Funcionalidad Limitada:</h5><br><p>Los jugadores que no cuenten con una version paga del juego tienen acceso a una version mas limitada de Minecraft, con caracteristicas y privilegios reducidos en comparación con la versión Premium.</p>

        <h5>Sin Acceso a Servidores Oficiales:</h5><br><p>Los usuarios no Premium no pueden acceder a los servidores oficiales de Minecraft, lo que limita la experiencia de juego. Se pierde el privilegio de poder unirse a los servidores mas reconocidos, donde generalmente están los mejores jugadores y los mejores servidores.</p><br>

        <h5>Menor Seguridad y Soporte:</h5><br><p>Las cuentas no premium pueden lelgar a ser más vulnerables a la piratería y al acceso no autorizado debido a la falta de medidas de seguridad que conlleva no jugar desde el launcher oficial. Ademas, los jugadores no premium pueden llegar a tener que esperar tiempos muchos mas prolongados para recibir soporte por parte de Mojang.</p><br>

        <h5>Personalización Básica:</h5><br><p>Aunque los jugadores con cuenta no premium pueden personalizar su experiencia de juego hasta cierto punto, esta no se compara con las herramientas que nos brinda tener cuenta oficial, como por ejemplo las skins sin tener que instalar nada, las capas, etc.</p>
        <h2>Conclusion:</h2><br>
        <p>En resumen, mientras que tanto las cuentas premium como las no premium ofrecen acceso al emocionante mundo de Minecraft, hay diferencias significativas en términos de funcionalidad, beneficios y seguridad. Aunque una cuenta premium proporciona acceso completo al juego y una serie de beneficios exclusivos, una cuenta no premium puede ser una opción viable para aquellos que desean probar el juego antes de realizar una compra o que no pueden permitirse adquirir una cuenta premium. Al final, la elección entre una cuenta premium y una cuenta no premium depende de las necesidades y preferencias individuales de cada jugador.</p>
        <img src="https://www.tmthosting.ar/userfiles/media/default/logotmtg-white2163e857.png" style="width:300px; margin: auto; padding-bottom: 1rem;"><br>
        <p>Igual quedate tranquilo, independientemente de si te compraste el Minecraft o no, en TMTGaming no diferenciamos a ningun jugador: Cualquier jugador puede disfrutar tanto de nuestros servidores como de nuestros hostings. Con este enlace podes ir a ver algunos de nuestros <a href="https://tmtgaming.ar/#Tab">Planes!</a>
      `
  },
  {
    "name": "diferencias-bedrock-y-java",
    "title": "Diferencias entre Minecraft Bedrock y Java",
    "description": "¿Siempre te preguntaste que tenia de distinto cada versión? Enterate!",
    "link": "/blog/diferencias-bedrock-y-java",
    "image": "https://t30911801.p.clickup-attachments.com/t30911801/c16e3130-13fe-42b0-86f0-b9ccebd0c24f/bed%20vs%20java.jpg",
    "content": `
        <h1 style="margin: 1;">¿Cuales son las diferencias entre Minecraft Bedrock y Java?</h1>
        <b style="margin: 1;">En este articulo descubriras las principales diferencias entre Minecraft Bedrock y Minecraft Java, empecemos:</b><br>
        <p style="margin-top: 1rem;">
        Minecraft, un fenómeno global con millones de jugadores, se presenta en dos versiones principales: Bedrock y Java. Cada una ofrece una experiencia única.
        <b>Bedrock</b>, disponible en móviles, consolas y Windows 10, se caracteriza por su accesibilidad y controles táctiles.
        Su precio único por plataforma te permite jugar en cualquier lugar.<br>
        <img src="https://staticg.sportskeeda.com/editor/2023/10/718f1-16964721120881-1920.jpg" style="margin: 1rem; width: 900px"><br>
        <p><b>Java</b>, por otro lado, se limita a PC con Windows, macOS y Linux. Ofrece la experiencia original de Minecraft con controles de teclado y mouse.<br>
        Su pago único te da acceso a Bedrock para Windows 10, permitiéndote disfrutar de ambas versiones.
        Las diferencias en la jugabilidad son sutiles. <br>Bedrock presenta algunas variaciones en la mecánica, como el comportamiento de los mobs. 
        Java, por su parte, conserva la experiencia clásica del juego.</p>
        <img src="https://pbs.twimg.com/media/GGfJEKwWoAA0X3d?format=jpg&name=large" style="margin: 1rem; width: 900px;"><br>
        <p>En cuanto a modificaciones, Bedrock tiene un soporte limitado a través del Marketplace oficial, mientras que Java cuenta con una amplia comunidad de mods que ofrece una gran variedad de opciones. Los servidores también varían, Bedrock ofrece servidores multijugador con diferentes características, algunos de pago. 
        Java, por su parte, tiene una amplia gama de servidores gratuitos y de pago, con diferentes temáticas y modos de juego.
        Las comunidades de ambas versiones también son diferentes.<br>
        Bedrock tiene una comunidad diversa y activa en plataformas móviles y consolas.
        Java, por su parte, tiene una comunidad más veterana y dedicada, con una gran cantidad de creadores de contenido y mods.</p><br>
        <h4>Entonces: ¿cuál elegir? La decisión depende de tus preferencias como jugador:</h4><br>
        <p>- Bedrock: Es ideal para una experiencia casual y accesible en diferentes plataformas.<br>
        - Java: Es ideal para la experiencia original de Minecraft, con mayor libertad para mods y servidores.<br>
        <b>Conclusión:</b><br>
        Bedrock es multiplataforma, ideal para jugar con amigos en diferentes dispositivos.<br>
        Java ofrece la experiencia clásica de Minecraft, con mayor flexibilidad para mods y servidores.
        ¡Disfruta de Minecraft!
        </p>
        <p>En TMTGaming solamente ofrecemos soporte para Java, pero tranquilo! Ya nos encontramos trabajando para poder ofrecer soporte tambien a los jugadores de Bedrock. Estamos en constante evolución e intentamos darle a la comunidad todas las herramientas para que puedan disfrutar del juego en su totalidad y un poco mas :). Para conocer nuestros servicios podes apretar el siguiente enlace <a href="https://tmtgaming.ar/#Tab">¡Planes!</a> allí podras ver todo lo que tenemos para ofrecerte.</p>
        <img src="https://www.tmthosting.ar/userfiles/media/default/logotmtg-white2163e857.png" style="width:300px; margin: auto;"><br>
      `
  },

  {
    "name": "como-funciona-el-panel-de-pterodactyl",
    "title": "¿Que es y como funciona el panel de Pterodactyl?",
    "description": "Descubri como funciona Pterodactyl, el panel idoneo para manejar tu servidor.",
    "link": "/blog/como-funciona-el-panel-de-pterodactyl",
    "image": "https://repository-images.githubusercontent.com/47508644/94a40200-a741-11e9-9f82-9d5b2ce1e8d7",
    "content": `
        <h1 style="margin: 1;">Guia de usuario de pterodactyl panel</h1>
        <b style="margin: 1;">Esta guía te ayudará a utilizar Pterodactyl Panel desde la interfaz del usuario.</b><br>
<b>1. Acceder a la interfaz del usuario</b><br>
<p style="margin-top: 0.3rem;">
Para acceder a la interfaz del usuario de Pterodactyl Panel, abre un navegador web y ve
a la dirección URL de tu panel. La URL de tu panel será algo como "https://tu_dominio.com/panel".<br></p>
<b>2. Iniciar sesión</b><br>
<p>En la página de inicio de sesión, introduce tu nombre de usuario y contraseña. 
Si no tienes un nombre de usuario y contraseña, puedes crearlos haciendo clic en el enlace "Registrarse"<br></p>

<b>Interfaz del usuario</b>
<p style="margin-bottom: 1rem;">La interfaz del usuario de Pterodactyl Panel está dividida en varias secciones:<br>

 <p style=""<b>Menú principal:</b> El menú principal contiene enlaces a todas las secciones del panel.<br>
 <b>Panel de control:</b> El panel de control te muestra una vista general de tu servidor, como el estado del servidor, el uso de recursos y los jugadores conectados.<br>
 <b>Servidores:</b> La sección de servidores te permite crear, administrar y eliminar servidores.<br>
 <b>Usuarios:</b> La sección de usuarios te permite crear, administrar y eliminar usuarios.<br>
 <b>Archivos:</b> La sección de archivos te permite administrar los archivos de tu servidor.<br>
 <b>Bases de datos:</b> La sección de bases de datos te permite administrar las bases de datos de tu   servidor.<br>
 <b>Tareas:</b> La sección de tareas te permite crear y administrar tareas programadas.<br>
 <b>Ajustes:</b> La sección de ajustes te permite configurar las opciones de tu panel.<br></p>
 <img src="https://pbs.twimg.com/media/GHDH8GLXAAARb0O?format=jpg&name=large" style="margin-bottom: 1rem;">

<b>¿Cómo usar Pterodactyl Panel?</b><br>
<p style="margin-bottom: 1rem;">Aquí hay algunos ejemplos de cómo usar Pterodactyl Panel:<br></p>

 <p style="margin-bottom: 1rem;"><b>Crear un servidor:</b> Haz clic en el botón "Crear servidor" en la sección de servidores.
 Selecciona el tipo de servidor que deseas crear y sigue las instrucciones.<br>
 <b>Iniciar un servidor:</b> Haz clic en el botón "Iniciar" junto al servidor que deseas iniciar.<br>
 <b>Detener un servidor:</b> Haz clic en el botón "Detener" junto al servidor que deseas detener.<br>
 <b>Reiniciar un servidor:</b> Haz clic en el botón "Reiniciar" junto al servidor que deseas reiniciar.<br>
<b>Instalar un mod:</b> Haz clic en el botón "Instalar mod" en la sección de archivos. Selecciona el mod que deseas instalar y sigue las instrucciones.<br>
 <b>Agregar un usuario:</b> Haz clic en el botón "Agregar usuario" en la sección de usuarios. Introduce el nombre de usuario, la dirección de correo electrónico y la contraseña del usuario.<br>
 <b>Cambiar la contraseña:</b> Haz clic en el botón "Cambiar contraseña" en la sección de ajustes. Introduce tu contraseña actual y la nueva contraseña.<br></p>
 <img src="https://pbs.twimg.com/media/GHDIZSgW8AAKTp7?format=jpg&name=medium">

<b>Recursos adicionales</b><br>

<p style="margin-bottom: 1rem;">- Sitio web oficial de Pterodactyl Panel: <a href="https://pterodactyl.io/: https://pterodactyl.io/">https://pterodactyl.io/</a> <br>
- Documentación oficial de Pterodactyl Panel: <a href="https://github.com/pterodactyl/documentation/tree/master">https://github.com/pterodactyl/documentation/tree/master</a><br>
- Comunidad de Pterodactyl Panel: <a href="https://github.com/pterodactyl/documentation/tree/master/community">https://github.com/pterodactyl/documentation/tree/master/community</a><br>
Espero que esta guía te haya sido útil.
Si tienes alguna pregunta, no dudes en dejar un comentario.<br></p>

<b>Aquí hay algunos consejos adicionales para usar Pterodactyl Panel:</b><br>

<p> Lee la documentación: La documentación oficial de Pterodactyl Panel es un excelente recurso para aprender a usar el panel.<br>
 <b>Únete a la comunidad:</b> La comunidad de Pterodactyl Panel es un gran lugar para hacer preguntas y obtener ayuda.<br>
 <b>Haz una copia de seguridad de tu servidor:</b> Es importante hacer una copia de seguridad de tu servidor con regularidad en caso de que algo salga mal.<br>
 <b>Mantén tu panel actualizado:</b> Es importante mantener tu panel actualizado con la última versión para obtener las últimas funciones y correcciones de errores.</p>
 <img src="https://www.tmthosting.ar/userfiles/media/default/logotmtg-white2163e857.png" style="width:300px; margin: auto;">
      `
  }
]
