<template>
    <Layout>
        <div v-if="plan">
            <header class="flex-centered">
                <img :src="plan.image" alt="">
                <div id="overlay"></div>
                <div id="description" class="flex-centered">
                    <h1>{{ plan.title }}</h1>
                    <p class="subtitle" v-for="(item, index) in detail.description" :key="index">
                        {{ item }}
                    </p>

                    <div class="attributes flex-centered" v-for="(item, index) in detail.attributes" :key="index">
                        <i class="fa" :class="item.icon" aria-hidden="true"
                            :style="`color: ${item.icon_color ?? 'white'}`"></i>
                        <p v-html="item.title"></p>
                    </div>
                </div>
            </header>
            <section id="buy-section" class="flex-centered">
                <img id="head" src="/Servidores/stevehead.png" alt="">
                <div class="buy-section">
                    <h1>¿Como comprar?</h1>
                    <p>Uso del Kit IRON , Multiple Home , Puede tener hasta 2 parcelas , Sombreros , Sombreros Animados ,
                        Recompensa Diaria Premium</p>

                </div>
                <div class="buy-section">
                    <h1>INSTALALO GRATIS</h1>
                    <p>Uso del Kit GOLD , /feed , Multiple Home , Puede tener hasta 3 parcelas , Sombreros , Sombreros
                        Animados , Trajes Animados, Gadgets ,Recompensa Diaria Premium</p>
                </div>

                <div class="buy-actions">
                    <a id="price">${{ plan.price }}</a>
                    <a id="buy" :href="detail.link">Adquirir</a>
                </div>

            </section>
        </div>
    </Layout>
</template>

<script setup>
import servidores from '../../../servidores.json';
import Layout from '@/components/layouts/Layout'
</script>

<script>
export default {
    mounted() {
        let name = this.$route.params.id;
        let plan = servidores.planes.find((x) => {
            if (x.name === name) return x;
        });
        this.plan = plan;
        this.detail = plan.detail;
    },
    data() {
        return {
            plan: null,
            detail: null,
        }
    }
}
</script>

<style scoped lang="scss">
header {
    height: 960px;
    position: relative;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;

        object-fit: cover;
        filter: brightness(.6) blur(2px);
    }

    #overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(circle, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    }

    #description {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        flex-direction: column;

        max-height: 90%;
        overflow-y: auto;

        pointer-events: none;
        user-select: none;

        h1 {
          text-align: center;
        }

        .subtitle {
            margin: 0;
            width: 60%;
            text-align: center;
        }
        .attributes {
            flex-direction: column;
            margin-top: 1em;

            i {
                margin-top: 1em;
                transform: scale(2);
                margin-bottom: 1em;
            }
        }
    }
}

p,
h1,
h2 {
    text-shadow: 1px 1px black;
    color: white;
}

#buy-section {
    width: 90%;
    margin: auto;
    margin-top: 15em;

    background: var(--azul-oscuro);
    height: 550px;

    border-radius: 50px;

    flex-direction: column;

    position: relative;

    img {
        width: 306px;
        height: 315px;

        position: absolute;
        top: 0;
        left: 0;

        transform: translate(-10%, -50%);
    }

    .buy-section {
        width: 60%;
        text-align: center;

        margin-bottom: 4em;

        h1 {
            color: #FFF;
            text-align: center;
            font-family: Inter;
            font-size: 26px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            letter-spacing: 5px;
        }

        p {
            color: #979797;
            text-align: center;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .buy-actions {
        margin-top: 2em;

        a {
            padding: 1em 2em;
            border-radius: 30px;
            margin: 0 1em;
            cursor: pointer;
            user-select: none;
        }

        #price {
            border: 1px solid #72DCE9;
        }

        #buy {
            color: white;
            background: linear-gradient(180deg, #71DBE8 0%, #2A8E9A 100%);
        }
    }
}

@media screen and (max-width: 500px) {
    #head {
        display: none;
    }

    #buy-section {
        height: fit-content;
        padding: 5em 0;

        .buy-section {
            width: 85% !important;
        }
    }
}

@media screen and (max-width: 1300px) {
  #head {
    width: 225px !important;
    height: 240px !important;
  }
}
</style>

<style lang="scss">
.attributes {
    pointer-events: none;
    user-select: none;

    p,
    b,
    h2 {
        text-align: center;
    }

    p,
    b {
        color: white;
        text-shadow: 1px 1px black;
    }

    h2 {
        text-align: center;
        margin-bottom: 0;
        text-shadow: 1px 1px black;

    }
}
</style>