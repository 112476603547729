<template>
    <ul class="social-copyright">
        <li>
            <a href="https://www.facebook.com/TMTGAMING.AR/" target="_blank"><i class="feather-facebook"/></a>
        </li>
        <li>
            <a href="https://www.instagram.com/tmtgaming.ar/" target="_blank"><i class="feather-instagram"/></a>
        </li>
    </ul>
</template>

<script>
    export default {
        name: 'SocialLinks'
    }
</script>
