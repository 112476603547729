<template>
    <div class="rn-service-area rn-section-gapTop">
        <div class="container">
            <div class="row">
                <div class="col-12 mb--50">
                    <h3 class="title" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">TMTGaming ofrece:</h3>
                </div>
            </div>
            <div class="row g-5">
                <template v-for="(service, index) in serviceList"
                          :key="`service-${index}`">
                    <div id="cards" v-if="index < serviceLimit" :class="serviceColumn">
                        <service-card :service-data="service"/>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import ServiceCard from '@/components/service/ServiceCard'

    export default {
        name: 'ServiceStyleOne',
        components: {ServiceCard},
        props: {
            serviceLimit: {
                type: Number,
                default: 3
            },
            serviceColumn: {
                type: String,
                default: 'col-xxl-4 col-lg-4 col-md-4 col-sm-4 col-4'
            }
        },
        data() {
            return {
                serviceList: [
                    {
                        image: require(`@/assets/images/icons/soporte.png`),
                        subtitle: 'TMTGaming',
                        title: 'Asesoria y Soporte',
                        description: `Estamos disponibles para resolver tus dudas o cualquier inconveniente técnico via Whatsapp. Trabajamos con servidores Oracle, que ofrecen robustez y escalabilidad. No dudes en contactarnos para una configurción Especial`,
                        link: '#'
                    },
                    {
                        image: require(`@/assets/images/icons/precio.png`),
                        subtitle: 'TMTGaming',
                        title: 'Confiabilidad',
                        description: `Precios en Pesos Argentinos, sin vueltas, sin recargos! El mejor precio / prestación ya que no sobrevendemos ni saturamos los servidores para poder brindar una experiencia premium`,
                        link: '#'
                    },
                    {
                        image: require(`@/assets/images/icons/seguridad.png`),
                        subtitle: 'TMTGaming',
                        title: `Seguridad`,
                        description: `Una vez ejecutada la compra nos ponemos en contacto para que empieces a disfrutar de tu host. Contamos con las medidas de seguridad necesarias para que disfrutes la experiencia con responsabilidad`,
                        link: '#'
                    }
                ],
            }
        }
    }
</script>

<style scoped>
#cards{
    display: flex;
    justify-content: center;
}
</style>