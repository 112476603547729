<template>
  <Layout>
    <header class="flex-centered">
      <section id="info-section" class="flex-centered">
        <h1 id="title">NUESTRO BLOG</h1>
      </section>
    </header>
    <section id="planes">
      <h1>Lee todos los articulos de TMT GAMING</h1>
      <div id="planes-cards">
        <div class="card" v-for="(blog, index) in blogs" :key="index" @click="goToBlog(blog)">
          <img :src="blog.image" alt="">
          <div class="info flex-centered">
            <div class="description">
              <b>{{ blog.title }}</b>
              <p>{{ blog.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </Layout>
</template>

<script setup>
import Layout from '@/components/layouts/Layout'
import blogs from '../../../blogs.js';
</script>

<script>
export default {
  mounted() {
    console.log(this.blogs)
  },
  methods: {
    goToBlog(blog) {
      this.$router.push(blog.link)
    }
  }
}
</script>

<style scoped lang="scss">
header {
  height: 200px;

  #info-section {
    height: 100%;
  }

  #info-section {
    width: 50%;
    background: transparent;
    padding: 2em;
    flex-direction: column;
    text-align: center;
  }

  background-image: url("https://www.minecraft.net/content/dam/games/minecraft/key-art/MC-Vanilla_Media-Block-Image_PC-Bundle-Keyart_800x450.jpg");
}

#planes {
  width: 90%;
  margin: auto;
  margin-top: 4em;

  h1 {
    font-size: 30px;
    letter-spacing: 3px;
    text-align: center;
  }

  #planes-cards {
    display: flex;
    justify-content: center;
    gap: 1em;
    flex-wrap: wrap;
    margin-top: 3em;

    .card {
      width: 370px;
      height: 310px;
      margin-bottom: 2em;

      background: transparent !important;

      img {
        width: 100%;
        height: 220px;
        border-radius: 20px;
        margin-bottom: 1em;
      }

      .info {
        justify-content: space-between;

        .description {
          width: 70%;
          margin-bottom: 1em;

          b {
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 1.44px;
          }

          p {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.24px;
          }
        }

        .buy {
          text-align: center;
          width: fit-content;
          padding: .5em 1em;
          border-radius: 20px;
          background: linear-gradient(#79E3F0, #1D7F8B);
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 1360px) {
  header {
    height: 200px;
  }

}

@media screen and (max-width: 500px) {
  header {
    flex-direction: column;

    #info-section {
      width: 100%;
    }
  }
}
</style>